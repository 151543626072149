/* popup */

.popup {
    display: flex;
    align-items: stretch;
    background-color: var(--whitebase);
    padding: 0;
    position: relative;
    top: calc(50% - 240px);
    left: calc(50% - 350px);
    width: 726px;
    min-height: 339px;
}

.iconWrapper {
    position: relative;
    width: 64px;
    background-color: var(--tertiary-600);
}

.icon {
    position: absolute;
    width: 39px;
    height: 44px;
    top: 54px;
    left: calc(50% - 16.5px);
    fill: var(--whitebase);
}

.body {
    padding-top: 23px;
    padding-bottom: 32px;
    padding-right: 64px;
    padding-left: 67px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.closeButtonWrapper {
    align-self: flex-end;
}

.title {
    margin: 0;
    padding: 0;
    font-family: "Raleway", sans-serif;
    font-size: 37px;
    line-height: 52px;
    color: var(--black-base);
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.buttonWrapper {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.buttonInner {
    width: 202px;
    display: flex;
    justify-content: space-between;
}

.inputText {
    padding: 10px;
    font-size: 15px;
    margin-top: 20px;
    border-radius: 5px;
}

.error {
    margin-top: 3px;
    color: #df1642;
    margin-bottom: 5px;
}
