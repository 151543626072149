.button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 8px 8px;
    border: 2px solid var(--tertiary-600);
    border-radius: 4px;
    height: 40px;
    min-width: 88px;
    background-color: var(--whitebase);
    color: var(--tertiary-600);
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    white-space: nowrap;
    outline: none;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.button:active {
    background-color: var(--black-100);
    outline: none;
}

.button:disabled {
    background-color: var(--black-100);
    color: var(--primary-200);
    cursor: auto;
    border-color: var(--black-100);
}

.button:focus {
    box-shadow: 0 0 0 2px var(--tertiary-600);
    outline: none;
}

.primary {
    background-color: var(--tertiary-600);
    color: var(--whitebase);
}

.primary:active {
    background-color: var(--tertiary-600);
}

.secondary {
    background-color: var(--tertiary-600);
    border-color: var(--tertiary-600);
    color: var(--whitebase);
}

.secondary:disabled {
    background-color: var(--tertiary-600);
    border-color: var(--tertiary-600);
    color: var(--whitebase);
}

.secondary:active {
    background-color: var(--tertiary-600);
    border-color: var(--tertiary-600);
}
