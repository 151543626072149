.container {
    background-color: #ededed !important;
    border: 2px solid #000 !important;
    border-radius: 5px !important;
}

.skipPaymentButton {
    box-sizing: border-box;
    font-family: inherit;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 12px;
    letter-spacing: normal;
    color: var(--tertiary-600);
    background-color: var(--whitebase);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 27px;
    min-width: 60px;
    padding: 2px 6px;
    border: 2px solid var(--tertiary-600);
    margin: 0;
    cursor: pointer;
    outline: none;
}

.skipPaymentContainer {
    padding-top: 0px !important;
    display: flex;
    justify-content: flex-end;
}
